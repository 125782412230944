import React, { useState } from 'react';
import { Typography, AppBar, Toolbar, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ICON, HUANBJT_LOGO } from '../utils/Constant';

export const HuanbjtToolbar = () => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const handleBackClick = () => {
        navigate('/');
    }

    const handleRemoveBgClick = () => {
        navigate('/detail', { state: { imageFile: null, requested: false } });
    }

    const handleAboutClick = () => {
      navigate('/about');
    }

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
        <AppBar position="fixed" style={{ boxShadow: '0px 1px 1px -1px gray' }}>
          <Toolbar style={{ backgroundColor: '#fff'}}>
            <IconButton edge="start" color="inherit">
                <img
                src={DEFAULT_ICON}
                style={{ width: '32px', height: '32px' }}
                alt=''
                onClick={handleBackClick}
                />
            </IconButton>
            <img src={HUANBJT_LOGO} 
                 style={{ maxWidth: '128px', maxHeight: '64px', height: '64px', marginLeft: '1px', cursor: 'pointer' }} 
                 onClick={handleBackClick}
                 alt=''
            />
            <Typography variant="h6" className="app-bar-title" 
              style={{ 
                color: isHovered ? 'orange' : 'rgb(69 69 69)', 
                cursor: 'pointer', 
                marginLeft: '32px',
                marginTop: '6px',
              }} 
              onClick={handleRemoveBgClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
            替换背景
            </Typography>
            <div style={{ marginLeft: 'auto' }}>
              <Typography variant="h6" className="app-bar-title" 
                style={{ 
                  color: 'rgb(69 69 69)', 
                  cursor: 'pointer', 
                  marginTop: '6px',
                }} 
                onClick={handleAboutClick}
              >
              关于
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
    );
};