import React from 'react';
import { Container, Typography } from '@mui/material';
import '../css/AboutPage.css'; // 创建一个 CSS 文件来管理样式

const AboutPage = () => {
  return (
    <div className="about-root">
      <Container maxWidth="lg" style={{ textAlign: 'left' }}>
        <div style={{ marginLeft: 'auto' }}>
          <Typography variant="h6" className="app-bar-title" style={{ fontFamily: '宋体, serif', color: 'rgb(69 69 69)', marginTop: '10px' }}>
          大家好！
          </Typography>
          <Typography variant="body1" style={{ fontFamily: '宋体, serif', fontSize: '1.2rem', color: 'rgb(69 69 69)', marginTop: '10px' }}>
          特别感谢你们一直以来对huanbjt的喜爱与支持，是你们赋予了这个小小的网站满满的生机与活力，就像点点繁星照亮了浩瀚夜空，让它在网络世界中熠熠生辉。
          </Typography>
          <Typography variant="body1" style={{ fontFamily: '宋体, serif', fontSize: '1.2rem', color: 'rgb(69 69 69)', marginTop: '10px' }}>
          说起创建这个网站的初衷，其实特别简单。我只是想给小朋友的照片修改背景颜色而已。但是在网上找工具的时候，我发现那些工具要么得下载各种各样的app；要么就是需要注册，还得付费! 于是，我干脆自己动手写了这个网站。这样，我自己可以用，也能分享给大家，让大家都能轻松地给照片换背景。后来，我申请了域名 huanbjt.cn，这就是 “换背景图” 的缩写，希望大家能一下子就记住。
          </Typography>
          <Typography variant="body1" style={{ fontFamily: '宋体, serif', fontSize: '1.2rem', color: 'rgb(69 69 69)', marginTop: '10px' }}>
          从一开始，我的目标就很明确，那就是要操作简单专业，而且永远免费，让每一个有需要的朋友都能毫无负担地使用。
          </Typography>
          <Typography variant="body1" style={{ fontFamily: '宋体, serif', fontSize: '1.2rem', color: 'rgb(69 69 69)', marginTop: '10px' }}>
          不过，现在有个小状况想跟大家说说。随着使用的朋友越来越多，就像潮水一般涌来，咱们的服务器有点不堪重负啦。这就好比一辆小马车要拉好多好多的货物，有些吃力呢。所以，大家在使用过程中可能偶尔会遇到加载缓慢或者卡顿的情况，我真的非常非常抱歉，就像给大家带来了一场不期而遇的小雨，影响了大家的好心情。
          </Typography>
          <Typography variant="body1" style={{ fontFamily: '宋体, serif', fontSize: '1.2rem', color: 'rgb(69 69 69)', marginTop: '10px' }}>
          如果你们觉得huanbjt还不错，就像你们在旅途中发现了一个宝藏小店一样，希望能给我一些支持哦。你们的支持就像温暖的阳光和肥沃的土壤，能让huanbjt这棵小树苗茁壮成长，走得更远，也是我们前进的动力，让我们更好的维护和装饰她更多新的功能。再次感谢你们一路以来的陪伴，你们是huanbjt最宝贵的财富！
          </Typography>
        </div>
        <img src={require("../images/support-me.jpeg")} alt="About Us" style={{ width: '30vh', height: 'auto', display: 'block', margin: '10px auto' }} />
      </Container>
    </div>
  );
};

export default AboutPage;