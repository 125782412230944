import React, { useState, useRef, useEffect } from 'react';
import { Button, Container, Typography, Box, Grid } from '@mui/material';
// import { toPng } from 'html-to-image';
import StarLoading from '../components/StarLoading';
import ColorPickerModal from '../components/ColorPickerModal';
import { useLocation } from 'react-router-dom';
import { DEFAULT_IMAGE, SVG_ICON_BG } from '../utils/Constant';
import { downloadedImageName, generateStars, getImageDimensions } from '../utils/Utils';
import { HuanbjtBottombar } from '../components/HBottomBar';
import '../css/App.css';

const DetailPage = () => {
  const location = useLocation();
  const { imageFile, requested } = location.state || {};
  const [imagePixelInfo, setImagePixelInfo] = useState();

  const [selectedImage, setSelectedImage] = useState(DEFAULT_IMAGE);
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const imageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [stars, setStars] = useState([]);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  useEffect(() => {
    const onMount = () => {
      // 点击上传【控件】的事件
      // 都有数值
      if (imageFile && requested) {
        uploadImage(imageFile);
      } else if (imageFile && !requested) {
        // 点击 SampleStyle 控件过来的
        // imageFile -> NON-NULL
        // requested -> false
        setSelectedImage(imageFile);

        // 获取Image的像素信息
        getImageDimensions(imageFile, function(error, dimensions) {
          if (error) {
              console.error(error);
          } else {
            setImagePixelInfo(dimensions);
          }
        });
      } else if (!imageFile && !requested){
        // HBottomBar 过来的
        // 都是 null
      }
      else { 
        // 从 HToolbar 过来的直接使用 DEFAULT_IMAGE
        // setSelectedImage(DEFAULT_IMAGE);
      }
    };


    
    onMount();
  }, [imageFile, requested]);

  /**
   * 上传图片事件  
   */ 
  const uploadImage = async (imageFile) => {
    // Start loading
    setLoading(true);
    setStars(generateStars());
  
    // Create form data with the image file
    const formData = new FormData();
    formData.append('image', imageFile);
  
    try {
      // Upload image to server
      const response = await fetch('/api/remove-bg', {
        method: 'POST',
        body: formData,
      });
  
      // Check if the response status is OK (status in the range 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Convert response to a blob
      const blob = await response.blob();
  
      // End loading
      setLoading(false);
      // Clear stars after upload
      setStars([]);
  
      // Create a URL for the blob and set the selected image
      const url = URL.createObjectURL(blob);
      setSelectedImage(url);

      // 获取Image的像素信息
      getImageDimensions(url, function(error, dimensions) {
        if (error) {
            console.error(error);
        } else {
          setImagePixelInfo(dimensions);
        }
      });  
    } catch (error) {
      // Handle errors
      console.error('Error uploading image:', error);
      setLoading(false); // Ensure loading is stopped even if there's an error
      setStars([]); // Clear stars even if there's an error
    }
  };  

  /**
   * 改变图片背景色的方法
   */
  const handleBackgroundColorChange = (color) => {
    if (selectedImage !== DEFAULT_IMAGE) {
      setBackgroundColor(color);
    } else {
      alert('请先上传一张需要换背景的图片.');
    }
  };

  /**
   * 下载按钮事件
   */
  const handleDownloadClick = (isHighDefinition) => {
    if (imageRef.current && selectedImage !== DEFAULT_IMAGE) {
      if (isHighDefinition) {
        // console.log("下载 [高清] image Info: ", imagePixelInfo);
        drawCanvas(imagePixelInfo.width, imagePixelInfo.height);
      } else {
        var previewImage = document.getElementById('previewImage');
        // console.log("下载 [非高清] image Info: ", previewImage);
        drawCanvas(previewImage.width, previewImage.height);
      }
    } else {
      alert('没有可下载的图片');
    }
  };

  const drawCanvas = (width, height) => {
    var previewImage = document.getElementById('previewImage');
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');

    canvas.width = width;  // 设置 Canvas 的宽度
    canvas.height = height; // 设置 Canvas 的高度

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    // ctx.drawImage(previewImage, 0, 0);

    // Replace original image with the modified one
    // previewImage.src = canvas.toDataURL();

    ctx.drawImage(previewImage, 0, 0, canvas.width, canvas.height);

    // Convert canvas to Blob
    canvas.toBlob(function(blob) {
      // Create a temporary link to trigger the download
      var link = document.createElement('a');

      const imageName = downloadedImageName();
      link.download = imageName + '.png';

      // Create a URL for the Blob
      link.href = URL.createObjectURL(blob);

      // Append the link to the body
      document.body.appendChild(link);

      // Simulate a click on the link to trigger the download
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
      }, 'image/png');
  }

  /**
   * ColorPicker 组件的回调方法
   */
  const closeColorPicker = () => {
    setColorPickerOpen(false);
  };
  const selectColor = (color) => {
    handleBackgroundColorChange(color);
  };

  /**
   * 背景/涂鸦/效果/AI设计 按钮
   */
  const openColorPicker = () => {
    setColorPickerOpen(true);
  };
  // const handleImageRemove = () => {
  // };
  // const handleImageEffects = () => {
  // };
  // const handleAI = () => {
  // };

  const fromBottom = (image) => {
    setBackgroundColor('transparent');
    location.state.imageFile=image;
    setSelectedImage(image);
  }

  const PixelInfo = (isHighDefinition) => {
    if (isHighDefinition) {
      if (imagePixelInfo) {
        const width = imagePixelInfo.width;
        const height = imagePixelInfo.height;
        const info = width + ' x ' + height;
        return info;
      }  
    } else {
      var previewImage = document.getElementById('previewImage');
      const width = previewImage.width;
      const height = previewImage.height;
      const info = width + ' x ' + height;
      return info;
    }
  }

  return (
    <div className="app-detail">
      <Container className="app-container" maxWidth={false} style={{ backgroundColor: '#fff', minHeight: '100vh', marginTop: '-196px' }}>
      <Box textAlign="center" className="content-box" display="flex" flexDirection="column" justifyContent="center">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <Box
              ref={imageRef}
              className="image-box"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: 371,
                maxHeight: 558,
                width: 371,
                height: 'auto',
                backgroundColor: backgroundColor,
                borderRadius: 0,
                position: 'relative' // Added to make StarLoading overlay work
              }}
            >
              <img
                id="previewImage"
                src={selectedImage}
                alt="Selected"
                style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }}
              />
              <StarLoading loading={loading} stars={stars} /> {/* Add StarLoading component here */}
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={2} alignItems="center">
              <Grid item>
                <Grid container direction="column" spacing={1} justifyContent="center">
                  {[
                    { index: '0', text: '背景色', icon: SVG_ICON_BG, action: openColorPicker, aux: '' },
                    // { index: '1', text: '涂鸦', icon: SVG_ICON_DOODLE, action: handleImageRemove, aux: '(功能开发中)' },
                    // { index: 'blue', text: '效果', icon: SVG_ICON_EFFECTS, action: handleImageEffects, aux: '(功能开发中)' },
                    // { index: 'green', text: 'AI设计', icon: SVG_ICON_AI, action: handleAI, aux: '(功能开发中)' },
                  ].map(({ index, text, icon, action, aux }) => (
                      <Grid item key={index} xs={2}>
                        <Box className="show_buttons" textAlign="center" justifyContent="left" backgroundColor="transparent">
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: 'white',
                                color: 'inherit',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                width: '30px',
                                height: '30px',
                                minWidth: '30px',
                                margin: '8px 0',
                                padding: 0,
                                borderRadius: '8px',
                              }}
                              onClick={ action }
                              // disabled={aux === '(功能开发中)'}
                            >
                              {icon}
                            </Button>
                            <Typography variant="h6"
                                        style={{
                                          color: text === '背景色' ? 'rgb(69, 69, 69)' : 'rgb(224,224,224)',
                                          marginLeft: '10px'
                            }}>
                              {text} {aux}
                            </Typography>
                        </Box>
                      </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  // color="secondary"
                  onClick={() => handleDownloadClick(false)}
                  disabled={selectedImage === DEFAULT_IMAGE}
                  style={{ 
                    borderRadius: '24px', 
                    fontWeight: '600', 
                    color: 'white', 
                    fontSize: '16px',
                    width: '328px',
                    height: '44px',
                    marginTop: '24px'
                  }}
                >
                  下载
                </Button>
              </Grid>
              <Grid item>
                <Typography 
                  style={{
                    color: 'rgb(141 153 174)',
                    marginLeft: '10px'
                  }}>
                  {selectedImage == DEFAULT_IMAGE ? '' : PixelInfo(false)}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDownloadClick(true)}
                  disabled={selectedImage === DEFAULT_IMAGE}
                  style={{ 
                    borderRadius: '24px', 
                    fontWeight: '600',
                    color: selectedImage === DEFAULT_IMAGE ? 'rgb(141, 153, 174)' :'rgb(69, 69, 69)',
                    fontSize: '16px',
                    backgroundColor: 'rgb(246, 246, 246)',
                    width: '328px',
                    height: '44px'
                  }}
                >
                  下载高清版
                </Button>
              </Grid>
              <Grid item>
                <Typography 
                  style={{
                    color: 'rgb(141 153 174)',
                    marginLeft: '10px'
                  }}>
                  {selectedImage == DEFAULT_IMAGE ? '' : PixelInfo(true)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ColorPickerModal
          open={colorPickerOpen}
          onClose={closeColorPicker}
          onSelectColor={selectColor}
        />
      </Box>
      <HuanbjtBottombar upload={uploadImage} updatePreviewImage={fromBottom} />
      </Container>
    </div>
  );
};

export default DetailPage;